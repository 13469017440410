<template>
    <div class="invention-team-data">
        <v-form
            ref="form"
            v-model="valid"
        >
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.TeamData.title[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.team.name"
                            outlined
                            :label="$t('InventionApply.TeamData.label[0]')"
                            placeholder=""
                            :hint="$t('InventionApply.TeamData.hint[0]')"
                            persistent-hint
                            :rules="twelveWordsRule"
                            class="border-orange-input form-input"
                        />
                        <v-text-field
                            v-model="form.team.school"
                            outlined
                            :label="$t('InventionApply.TeamData.label[1]')"
                            placeholder=""
                            :hint="$t('InventionApply.TeamData.hint[1]')"
                            :rules="emptyRule"
                            persistent-hint
                            class="border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.TeamData.title[1]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.TeamData.tips[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-action">
                        <div
                            class="delete-btn btn"
                            @click="deleteTeacher"
                        >
                            - {{ $t('InventionApply.TeamData.deleteMember') }}
                        </div>
                        <div
                            class="add-btn btn"
                            @click="addTeacher"
                        >
                            + {{ $t('InventionApply.TeamData.addMember') }}
                        </div>
                    </div>
                    <div class="teacher-list">
                        <div
                            v-for="(item, index) in form.team.teachers"
                            :key="index"
                            class="teacher-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="`${$t('InventionApply.TeamData.label[2]')}${index + 1}`"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    persistent-hint
                                    :rules="emptyRule"
                                    class="border-orange-input form-input"
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    :label="$t('InventionApply.TeamData.label[3]')"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    :rules="phoneRule"
                                    persistent-hint
                                    class="border-orange-input form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.title"
                                    outlined
                                    :label="$t('InventionApply.TeamData.label[4]')"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    persistent-hint
                                    :rules="emptyRule"
                                    class="border-orange-input form-input"
                                />
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    :label="$t('InventionApply.TeamData.label[5]')"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    :rules="emailRule"
                                    persistent-hint
                                    class="border-orange-input form-input"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.TeamData.title[2]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.TeamData.tips[1]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-action">
                        <div
                            class="delete-btn btn"
                            @click="deleteMember"
                        >
                            - {{ $t('InventionApply.TeamData.deleteMember') }}
                        </div>
                        <div
                            class="add-btn btn"
                            @click="addMember"
                        >
                            + {{ $t('InventionApply.TeamData.addMember') }}
                        </div>
                    </div>
                    <div class="teacher-list">
                        <div
                            v-for="(item, index) in form.team.members"
                            :key="index"
                            class="member-item"
                        >
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.name"
                                    outlined
                                    :label="memberNameLabel(index)"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    persistent-hint
                                    :rules="emptyRule"
                                    class="border-orange-input form-input"
                                />
                                <v-text-field
                                    v-model="item.phone"
                                    outlined
                                    :label="$t('InventionApply.TeamData.label[8]')"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    :rules="phoneRule"
                                    persistent-hint
                                    class="border-orange-input form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.department"
                                    outlined
                                    :label="$t('InventionApply.TeamData.label[9]')"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    persistent-hint
                                    :rules="emptyRule"
                                    class="border-orange-input form-input"
                                />
                                <v-text-field
                                    v-model="item.email"
                                    outlined
                                    :label="$t('InventionApply.TeamData.label[10]')"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    :rules="emailRule"
                                    persistent-hint
                                    class="border-orange-input form-input"
                                />
                            </div>
                            <div class="form-row">
                                <v-text-field
                                    v-model="item.grade"
                                    outlined
                                    :label="$t('InventionApply.TeamData.label[11]')"
                                    placeholder=""
                                    :hint="$t('InventionApply.TeamData.hint[3]')"
                                    persistent-hint
                                    :rules="emptyRule"
                                    class="border-orange-input form-input"
                                />
                                <div class="pdf-row">
                                    <v-file-input
                                        v-model="item.tmpPdf"
                                        accept=".pdf"
                                        :label="$t('InventionApply.TeamData.label[12]')"
                                        outlined
                                        placeholder=""
                                        :hint="$t('InventionApply.TeamData.hint[2]')"
                                        persistent-hint
                                        class="border-orange-input form-input"
                                        :rules="[pdfRule(index)]"
                                        @change="
                                            (file) =>
                                                handlePdfChange(
                                                    `form.team.members[${index}].pdfFile`,
                                                    file,
                                                    `form.team.members[${index}].tmpPdf`
                                                )
                                        "
                                    />
                                    <a
                                        v-if="form.team.members[index].pdfFile"
                                        :href="$static(form.team.members[index].pdfFile)"
                                        target="_blank"
                                        class="preview-link"
                                    >{{ $t('InventionApply.TeamData.link') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="apply-form-block team-image-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.TeamData.title[3]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="label">
                        {{ $t('InventionApply.TeamData.label[13]') }}
                    </div>
                    <v-card
                        elevation="4"
                        class="preview-card rounded-lg"
                    >
                        <v-tooltip
                            top
                            nudge-top="-90"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-img
                                    height="300px"
                                    :src="$static(form.team.previewImg)"
                                    class="og-image"
                                    :contain="loading"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-file-input
                                        v-model="teamImg"
                                        accept="image/png, image/jpeg"
                                        class="upload pa-0 ma-0"
                                        hide-input
                                        truncate-length="0"
                                        prepend-icon="mdi-upload"
                                        dark
                                        @change="handleImageUpload"
                                    />
                                </v-img>
                            </template>
                            <span>{{ $t('InventionApply.TeamData.image') }}</span>
                        </v-tooltip>
                    </v-card>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.TeamData.title[4]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox-q[0]')"
                        style="pointer-events: none;"
                        class="checkbox checkbox-title"
                        :rules="refersRule"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][0]')"
                        value="學校活動公告(學校官網、海報、育成中心等)"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][1]')"
                        value="獎金獵人平台"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][2]')"
                        value="FUN大視野官網"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][3]')"
                        value="FUN大視野FACEBOOK"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][4]')"
                        value="FUN大視野INSTAGRAM"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('InventionApply.TeamData.checkbox[0][5]')"
                            value="歷屆團隊推薦，團隊名:"
                            color="#F97E2B"
                            class="checkbox"
                        />
                        <v-text-field
                            v-model="refers1"
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('InventionApply.TeamData.checkbox[0][6]')"
                            value="親朋好友推薦，推薦人:"
                            color="#F97E2B"
                            class="checkbox"
                        />
                        <v-text-field
                            v-model="refers2"
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][7]')"
                        value="曾經提案過"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][8]')"
                        value="原本就知道"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.team.refers"
                        :label="$t('InventionApply.TeamData.checkbox[0][9]')"
                        value="慈濟基金會宣傳平台(官網、IG、LINE、大愛台等)"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <div style="display: flex;flex-wrap: wrap">
                        <v-checkbox
                            v-model="form.team.refers"
                            :label="$t('InventionApply.TeamData.checkbox[0][10]')"
                            value="其他:"
                            color="#F97E2B"
                            class="checkbox"
                        />
                        <v-text-field
                            v-model="refers3"
                            class="checkbox-input border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block" />
                <div class="form-content">
                    <div class="actions">
                        <div
                            class="btn white-btn"
                            @click="resetForm"
                        >
                            {{ $t('InventionApply.TeamData.button[0]') }}
                        </div>
                        <div
                            class="btn blue-btn"
                            @click="save"
                        >
                            {{ $t('InventionApply.TeamData.button[1]') }}
                        </div>
                        <div
                            class="btn orange-btn"
                            @click="nextStep"
                        >
                            {{ $t('InventionApply.TeamData.button[2]') }}
                        </div>
                    </div>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import _ from 'lodash';
import {
    mapState, mapMutations, mapActions,
} from 'vuex';
import { compress } from 'jpegasus';

export default {
    components: {},
    data() {
        return {
            valid: false,
            loading: false,
            teamImg: null,
            refers1: '',
            refers2: '',
            refers3: '',
            // rules
            emptyRule: [(v) => !!v || this.$t('InventionApply.TeamData.rule[0]')],
            twelveWordsRule: [
                (v) => !!v || this.$t('InventionApply.TeamData.rule[0]'),
                (v) => v.length <= 12 || this.$t('InventionApply.TeamData.rule[1]'),
            ],
            emailRule: [
                (v) => !!v || this.$t('InventionApply.TeamData.rule[0]'),
                (v) => /.+@.+\..+/.test(v) || this.$t('InventionApply.TeamData.rule[2]'),
                (v) => !v.match(/\s/) || this.$t('InventionApply.TeamData.rule[3]'),
            ],
            phoneRule: [
                (v) => parsePhoneNumber(v, 'TW').isPossible() || this.$t('InventionApply.TeamData.rule[4]'),
            ],
            refersRule: [
                (v) => v.length >= 1 || this.$t('InventionApply.TeamData.rule[0]'),
            ],
            pdfRule(index) {
                if (this.form.team.members[index].pdfFile) {
                    return true;
                }
                return this.$t('InventionApply.TeamData.rule[5]');
            },
        };
    },
    computed: {
        ...mapState('mInventionApply', ['form', 'refersTeamName', 'refersRecommendName', 'refersOther']),
        applyId() {
            return this.$route.params.id;
        },
    },
    created() {
    },
    mounted() {
        if (this.applyId !== 'new') {
            this.loadApply(this.applyId);
        }
        setTimeout(() => {
            this.init();
        }, 2000);
    },
    methods: {
        ...mapMutations('mNotification', ['handleError', 'setAlert']),
        ...mapMutations('mInventionApply', ['updateRefersTeamName', 'updateRefersRecommendName', 'updateRefersOther']),
        ...mapActions('mInventionApply', ['saveTeam', 'loadApply']),
        init() {
            this.refers1 = this.refersTeamName;
            this.refers2 = this.refersRecommendName;
            this.refers3 = this.refersOther;
        },
        deleteTeacher() {
            if (this.form.team.teachers.length > 1) {
                this.form.team.teachers.splice(-1);
            }
        },
        addTeacher() {
            if (this.form.team.teachers.length < 3) {
                this.form.team.teachers.push({
                    name: '',
                    phone: '',
                    title: '',
                    email: '',
                });
            }
        },
        memberNameLabel(index) {
            if (index === 0) {
                return this.$t('InventionApply.TeamData.label[6]');
            }
            return `${this.$t('InventionApply.TeamData.label[7]')}${index}`;
        },
        deleteMember() {
            if (this.form.team.members.length > 2) {
                this.form.team.members.splice(-1);
            }
        },
        addMember() {
            if (this.form.team.members.length < 6) {
                this.form.team.members.push({
                    name: '',
                    phone: '',
                    department: '',
                    email: '',
                    grade: '',
                    tmpPdf: null,
                    pdfFile: '',
                });
            }
        },
        handleFiles(file) {
            return compress(file, {
                maxHeight: 800,
                maxWidth: 800,
                quality: 1,
                fixImageOrientation: false,
            });
        },
        async onUploadImage(key, file) {
            const compressFile = await this.handleFiles(file); // 可以找其他壓縮方式
            _.set(this, key, URL.createObjectURL(compressFile));
        },
        handleImageUpload(file) {
            if (file) {
                if (file.size > 1024 * 1024 * 2) {
                    this.setAlert({
                        message: this.$t('alertMessage.data-2mb'),
                        color: 'error',
                    });
                    return;
                }
                this.onUploadImage('form.team.previewImg', file);
            }
        },
        handlePdfChange(key, file, tmpKey) {
            if (!file) {
                _.set(this, key, '');
            }
            if (file) {
                if (file.size > 1024 * 1024 * 3) {
                    this.setAlert({
                        message: this.$t('alertMessage.data-3mb'),
                        color: 'error',
                    });
                    _.set(this, key, '');
                    _.set(this, tmpKey, '');
                    return;
                }
            }
            _.set(this, key, URL.createObjectURL(file));
        },
        resetForm() {
            this.form.team = {
                name: '',
                school: '',
                previewImg: '',
                refers: [],
                teachers: [
                    {
                        name: '',
                        phone: '',
                        title: '',
                        email: '',
                    },
                ],
                members: [
                    {
                        name: '',
                        phone: '',
                        department: '',
                        email: '',
                        grade: '',
                        tmpPdf: null,
                        pdfFile: '',
                    },
                    {
                        name: '',
                        phone: '',
                        department: '',
                        email: '',
                        grade: '',
                        tmpPdf: null,
                        pdfFile: '',
                    },
                ],
            };
        },
        updateRefers() {
            this.updateRefersTeamName(this.refers1);
            this.updateRefersRecommendName(this.refers2);
            this.updateRefersOther(this.refers3);
            // 修改報名來源資料
            const option1 = '歷屆團隊推薦，團隊名:';
            const option2 = '親朋好友推薦，推薦人:';
            const option3 = '其他:';
            this.form.team.refers = this.form.team.refers.map((item) => {
                if (item === option1) {
                    return `${option1 + this.refers1}`;
                } if (item === option2) {
                    return `${option2 + this.refers2}`;
                } if (item === option3) {
                    return `${option3 + this.refers3}`;
                }
                return item;
            });
        },
        async save() {
            this.updateRefers();
            await this.saveTeam({
                form: this.form.team,
                applyId: this.applyId,
            });
        },
        async nextStep() {
            this.updateRefers();
            this.$refs.form.validate();
            if (this.valid) {
                try {
                    await this.saveTeam({
                        form: this.form.team,
                        applyId: this.applyId,
                    });
                    this.$router.push({
                        name: 'InventionProposal',
                        params: {
                            id: this.applyId,
                        },
                    });
                } catch {
                    this.setAlert({
                        message: this.$t('alertMessage.data-no-write'),
                        color: 'error',
                    });
                }
            } else {
                this.setAlert({
                    message: this.$t('alertMessage.data-no-write'),
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss">
.invention-team-data {
  .form-content {
    .teacher-item:not(:first-child) {
      border-top: 1px dashed $color-orange;
      padding-top: 25px;
    }

    .member-item:not(:first-child) {
      border-top: 1px dashed $color-orange;
      padding-top: 25px;
    }
  }

  .team-image-block {
    .preview-card {
      width: 300px;
      height: 300px;
    }

    .label {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.1em;
      color: $color-gray;
      margin-bottom: 23px;
    }

    .upload {
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(196, 196, 196, 0.4);
      border: 1px solid #ffffff;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .v-input__prepend-outer {
        margin: 0 !important;
      }
    }
  }
}
.checkbox-title{
    margin-bottom: 15px;
}
.checkbox-title .v-label{
    color:#404040 !important;;
}
.checkbox-title .v-input--selection-controls__input{
    display: none;
}
.checkbox-input{
    padding-top: 0 !important;
    margin-top: 0 !important;
    width: 100%;
    flex: unset;
}
@media (min-width: 768px) {
    .checkbox-input{
        max-width: 185px !important;
    }
}

</style>
